body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#070606;
  font-family:'Josefin Sans';
  font-size:10pt;
}

nav
{
  padding-bottom:15px;
  text-align:center;
}

nav img
{
  width:100%;
  padding-top:10px;
}

h1
{
  text-transform:uppercase;  
  justify-content:center;
  text-align: left;
  display:flex;
  justify-content:center;
  align-items:flex-star;
  font-family:'Josefin Sans';
  letter-spacing: 0.04em;
  border-bottom:2px solid;
  border-top:2px solid;
  font-weight:normal;
  margin-bottom:0px;
}

h2
{
  
  display:inline-block;
  text-transform:uppercase;
  letter-spacing: 0.3em;
  font-family:'Josefin Sans';
  padding-bottom:10px;
  padding-top:15px;
  border-bottom:2px solid;
  border-top:2px solid;
  min-width:600px;
  margin-top:52px;
  font-size:18pt;
}

h3, h4
{
  letter-spacing:0.3em;
}

.container {
  text-align: left;
  justify-content:center;
  align-items:center;
  display:flex;
}

.content
{
  width:100%;
}

.colabLogo
{
  width:100px;
  height:100px;
  max-width:100px;
  max-height:100px;
  padding:20px;
  object-fit:scale-down !important;
}

.lhs,.rhs
{
   display: inline-block;
  margin: 50px;
  vertical-align:top;
}

.lhs
{
    width: 50%;
 }

.rhs
{
    width: 30%;
}

.rhs p
{
  padding-top:1em;
}

img 
{
  max-width:100%;
  max-height:100%;
}

.border
{
  border-color:#eeeeee;
  border-width:1px;
  border-style:solid;
  border-radius:6px;
}

.imageWrapper
{
  margin: 16px;
  float:left;
  padding-right:10px;
  padding-bottom:10px;
  margin: 0 auto;
  width: 50%;
  max-width: 400px; /* Change the max-width to your desired size */
  order: 1;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width:600px;
  margin-left:auto;
  margin-right:auto;
}

.subSection
{
  background-color:#34373c;
  width:90%;
  padding:5%;
}

.homePage
{
  font-size:16pt;
  text-align:center;
}

.homePage img
{
  width:100%;
  max-height:600px;
  object-fit:cover;
}

.blog img
{
  width:auto;
  max-height:auto;
}

.homePage .fullImage
{
  object-fit:contain;
  max-width:100%;
  max-height:100%;
}

.title
{
  max-width:600px;
  display:block;
  margin-right:auto;
  margin-left:auto;
  padding-top:2em;
}

.homePage .fullSize
{
  max-height:100%;
}

.homePage p
{
  width:80%;
  max-width:600px;
  margin-left:auto;
  margin-right:auto;
  letter-spacing:1px;
  line-height:28px;
  margin-bottom:42px;
}

.hero
{
  width:100%;
  animation-name: zoom-in-zoom-out;
  animation-duration:60s;
  animation-timing-function:ease-in-out;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.logo {
  width: 100%;
  text-align: center;
  padding-top:20px;
}

.logo h1 a {
  font-family:'Elsie';
  color:#070606;
  text-decoration:none;
  text-transform:none;  
  font-size:40pt;
}

.horizontal-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap:wrap;
}

.menuItem {
  margin: 0 10px;
  text-transform:uppercase;
  text-decoration: none;
}

.menuItem a
{
  text-transform:uppercase;
  text-decoration: none;
  color:#070606;
  font-weight:bold;
  letter-spacing: 2px;
}

.menuItem a:hover
{
  text-transform:uppercase;
  text-decoration: none;
  color:#444444;
}

.footerItem
{
  display:inline-block;
  text-align:center;
  vertical-align:middle;
  padding-bottom:40px;
  font-size:16pt;
  width:100%;
}

.footerItem a
{
  vertical-align:middle;
}
  
.imageGrid
{
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position:relative;
  width:100%;
  display: -webkit-box; 
  display: -ms-flexbox; 
  width: auto;
}

.imageGrid_column {
  padding: 10px; 
  background-clip: padding-box;
}

.image-element-class
{
  padding:25px;
}


.blog
{
  list-style-type: none;
  border:1px solid #eeeeee;
  padding:5px;
  margin:5px;
}

@media (max-width: 768px) {

.homePage
{
  font-size:14pt;
}

  .imageGrid .thumbnail-container{
      width:calc(50%-10px);
  }
  h1
  {
    font-size:40pt;
  }
  .menuItem a
  {
    font-size:8pt;
  }
  h2
  {
    width:100%;
    font-size:12pt;
  }
  .logo
  {
    padding-top:0px;
  }
  .logo h1 a {
    font-size:30pt;
  }
  nav img
  {
    width:80%;
  }
}

@media (max-width: 480px) {

.homePage
{
  font-size:12pt;
}

  .banner
  {
    background-image:none;
    height:0px;
  }
  .header
  {
    padding-top:0px;
  }
  .imageGrid .thumbnail-container
  {
    width:100%;
  }
  h2
  {
    min-width:0;
    font-size:12pt;
  }
  .logo
  {
    padding-top:0px;
  }
  .logo h1 a {
    font-size:16pt;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  max-width: 80vh;
  max-height: 80vh;
  overflow: auto;
}

.modal-content img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

form
{
  border-radius:3px;
  padding:5px;
}

form input
{
  padding:10px;
  border-top:none;
  border-left:none;
  border-right:none;
}

button
{
  padding:10px;
  margin:10px;
  background-color: #555555;
  border: 1px solid #555555;
  color:#eeeeee;
  border-radius:3px;
}
